body {
  margin: 0;
  font: 14px/1.3 helvetica, sans-serif;
}

header {
  grid-area: header;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;

  background: #111;
  color: #fff;
}

header a {
  color: #fff;
}

header .logo {
  display: block;
}

header p {
  margin: 0;
}

header nav {
  flex-grow: 1;
}

header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

header nav li a {
  padding: 6px 8px;
  border-radius: 4px;
  text-decoration: none;
  background: #333;
}

header nav li a:hover {
  background: #444;
}
