body {
  margin: 0;
  font: 14px / 1.3 helvetica, sans-serif;
}

header {
  color: #fff;
  background: #111;
  grid-area: header;
  align-items: center;
  gap: 12px;
  padding: 8px;
  display: flex;
}

header a {
  color: #fff;
}

header .logo {
  display: block;
}

header p {
  margin: 0;
}

header nav {
  flex-grow: 1;
}

header nav ul {
  justify-content: flex-end;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

header nav li a {
  background: #333;
  border-radius: 4px;
  padding: 6px 8px;
  text-decoration: none;
}

header nav li a:hover {
  background: #444;
}

html, body {
  height: 100%;
}

.wrap {
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-areas: "header"
                       "app";
  width: 100%;
  height: 100%;
  display: grid;
}

#root {
  grid-area: app;
}

#app {
  --editor-width: 50%;
  grid-template-areas: "editor-toolbar resize output-toolbar"
                       "editor resize output"
                       "editor resize errors";
  grid-template-columns: minmax(300px, var(--editor-width)) 1px minmax(450px, 1fr);
  grid-template-rows: max-content 1fr fit-content(20%);
  height: 100%;
  display: grid;
}

.editor {
  grid-area: editor;
}

.cm-editor {
  background: #fff;
  height: 100%;
  overflow: auto;
}

.cm-editor.cm-focused {
  outline: none;
}

.cm-editor .cm-scroller {
  overflow: auto;
}

.errors {
  background: #eee;
  border-top: 1px solid #ccc;
  grid-area: errors;
  margin: 0;
  overflow: auto;
}

.errors table {
  border-collapse: collapse;
  border-style: hidden;
}

.errors td {
  border: 1px solid #ddd;
  padding: 8px;
}

.errors .level {
  text-align: center;
}

.errors .message {
  width: 100%;
}

.errors .level span {
  text-transform: capitalize;
  border-radius: 2px;
  padding: 3px 4px 2px;
  font-size: 12px;
}

.errors .level span.error {
  color: #fff;
  background: #f33;
}

.errors .level span.warning {
  color: #000;
  background: #fc3;
}

.editor-toolbar {
  grid-area: editor-toolbar;
  justify-content: center;
}

.output-toolbar {
  grid-area: output-toolbar;
  justify-content: center;
}

.toolbar {
  background: #eee;
  border-bottom: 1px solid #ccc;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  padding: 8px;
  display: flex;
}

.toolbar .toolbar-item {
  gap: 4px;
  display: grid;
}

.toolbar .toolbar-flexible-space {
  flex-grow: 1;
}

.toolbar .toolbar-item-group {
  gap: 4px;
  display: flex;
}

.toolbar-item label {
  text-align: center;
  font-size: 12px;
}

.output {
  background: #ddd;
  grid-area: output;
  grid-template-rows: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

.output.invalid > * {
  opacity: .5;
  transition-delay: .2s;
  transition-duration: 0;
  transition-property: opacity;
}

.output .raw {
  white-space: pre-wrap;
  padding: 8px;
  font-family: monospace;
  overflow: auto;
}

@media (width <= 750px) {
  #app {
    grid-template: "editor-toolbar" max-content
                   "editor" 30%
                   "output-toolbar" max-content
                   "output" 1fr
                   "errors" fit-content(20%)
                   / auto;
  }

  .output-toolbar {
    border-top: 1px solid #ccc;
  }
}

@media (width <= 450px) {
  .editor {
    font-size: 16px;
  }
}

.resize {
  z-index: 1;
  background: #ccc;
  grid-area: resize;
  position: relative;
}

.resize-handle {
  cursor: ew-resize;
  width: 7px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3px;
}

@media (width <= 750px) {
  .resize {
    display: none;
  }
}
/*# sourceMappingURL=index.4a2b3917.css.map */
